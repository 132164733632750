<template>
  <div>
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Warehouse</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedWarehouse"
            :options="optionWarehouse"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLableWarehouse"
            @select="getTransportPlan"
          />
        </div>
      </div>
    </div>
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Shipment Code</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedTransportPlan"
            :options="optionTransportPlan"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            label="shipment_number"
            track-by="id"
            placeholder="Type to search"
            :searchable="true"
          />
        </div>
      </div>
    </div>
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Delivery Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input
            class="w-full"
            v-model="date"
            type="date"
            :min="minDate()"
            v-validate="'required'"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button
            @click="onClickBtnFilter"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            title="Search"
          >
            Filter</vs-button
          >
        </div>
      </div>
    </div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th
                    ><vs-checkbox
                      v-model="selectAll"
                      @input="handleSelectAll"
                    />ALL</vs-th
                  > -->
        <vs-th sort-key="code">Customer Data</vs-th>
        <vs-th sort-key="name">Delivery Order</vs-th>
        <vs-th sort-key="name">DO Status</vs-th>
        <vs-th sort-key="name">Operation Zone</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <ul>
              <li>
                {{ data[indextr].customer_name }}
              </li>
              <li>
                {{ data[indextr].customer_code }}
              </li>
            </ul>
          </vs-td>
          <vs-td>
            {{ data[indextr].delivery_order_code }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
          <vs-td>
            {{ data[indextr].zone_code }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Reassign to </span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedVehicle"
            :options="optionVehicle"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="300"
            :limit="7"
            track-by="id"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelVehicle"
            name="vehicleReassign"
            v-validate="'required'"
          />
          <span v-show="errors.has('vehicleReassign')" class="text-danger">{{
            errors.first("vehicleReassign")
          }}</span>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-6/12 w-full">
        <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
          >Re-Assign</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  mounted() {
    this.getWarehouse();
  },

  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      table2: this.tableDefaultState(),
      transportPlan: [],
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      cartVehicleAssigment: [],
      cartVehicles: [],
      IDItem: [],
      warehouseID: null,
      date: moment().format("YYYY-MM-DD"),
      optionWarehouse: [],
      selectedWarehouse: {},
      optionTransportPlan: [],
      selectedTransportPlan: {},
      optionVehicle: [],
      selectedVehicle: null,
      showModal: false,
      modalData: [],
      appendVehicles: [],
      indextrPopup: null,
      dateTp: "",
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/vehicle-reassigment/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            date: this.date,
            warehouse_id: this.selectedWarehouse.id,
            transport_plan_id: this.selectedTransportPlan.id,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            if (this.table.data) {
              for (let index = 0; index < resp.data.records.length; index++) {
                this.table.data[index]["indextr"] = index;
                this.table.data[index]["vehicle"] = [];
              }
            }
            console.log("weee", this.table.data);

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    onClickBtnFilter() {
      this.warehouseID = this.selectedWarehouse.id;

      this.getData();
      this.getVehicle();
    },
    SubmitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.Submit();
        }
        return;
      });
    },
    Submit() {
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/vehicle-reassigment/re-assign-vehicle", {
          transport_plan_id: this.selectedTransportPlan.id,
          vehicle_id: this.selectedVehicle.vehicle_id,
          driver_availability_id: this.selectedVehicle.id,
          vehicle_number: this.selectedVehicle.vehicle_number,
          driver_name: this.selectedVehicle.driver_name,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "shipment has been reassigned successfully",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
            this.getVehicle();
            this.selectedVehicle = null;
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                this.selectedWarehouse = {
                  id: this.$userLogin.warehouse_id,
                  code: this.$userLogin.warehouse_code,
                  name: this.$userLogin.warehouse_name,
                };
              } else {
                this.optionWarehouse = [];
                this.selectedWarehouse = {};
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },
    getTransportPlan() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-transport-plan", {
          params: {
            length: this.table2.length,
            page: this.table2.page,
            search: this.table2.search,
            order: this.table2.order,
            sort: this.table2.sort,
            warehouse_id: this.selectedWarehouse.id,
            is_assigned: true,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionTransportPlan = resp.data.records;

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getVehicle() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/vehicle-reassigment/vehicle-available", {
          params: {
            length: this.table2.length,
            page: this.table2.page,
            search: this.table2.search,
            order: this.table2.order,
            sort: this.table2.sort,
            date: this.date,
            warehouse_id: this.selectedWarehouse.id,
            vehicle_id: this.selectedTransportPlan.vehicle_id,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.optionVehicle = resp.data.records;
            console.log("weeekkkkkk", this.optionVehicle);

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLabelVehicle({ vehicle_number, driver_name }) {
      return `${vehicle_number}-${driver_name}`;
    },
    minDate() {
      // Create a new Date object
      const date = new Date();

      // Get the time in GMT+7
      const gmt7Time = new Date(date.getTime() + 7 * 60 * 60 * 1000);

      // Extract the year, month, and date
      const year = gmt7Time.getUTCFullYear();
      const month = String(gmt7Time.getUTCMonth() + 1).padStart(2, "0");
      const day = String(gmt7Time.getUTCDate()).padStart(2, "0");

      // Extract the hours and minutes
      const hours = String(gmt7Time.getUTCHours()).padStart(2, "0");
      const minutes = String(gmt7Time.getUTCMinutes()).padStart(2, "0");

      // Format the date and time to a string suitable for the input type
      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    //if select cartVehicles more than 1 then first data will be unselected
    cartVehicles: {
      handler: function (val) {
        if (val.length > 1) {
          this.cartVehicles.shift();
        }
      },
      deep: true,
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style>
.vs-popup--background {
  opacity: 0.05;
}

.con-vs-popup .vs-popup {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 100;
  width: 60% !important;
  margin: 10px;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-animation: rebound 0.3s;
  animation: rebound 0.3s;
}
</style>
